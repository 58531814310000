// @import '~bootstrap';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/utilities/api';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/forms';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // background-color: #282c34;
    min-height: 100vh;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // font-size: calc(10px + 2vmin);
    // color: white;
    color: #282c34;
}
html, #root {
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
